@charset "UTF-8";
/*
 * variables
 */
/* 
  * Safarの100vhを挙動を改善
  * @see https://coliss.com/articles/build-websites/operation/css/css-fix-for-100vh-in-ios.html
  */
@supports (-webkit-touch-callout: none) {
  body {
    height: -webkit-fill-available;
  }
}
/*
 * universal selector
 */
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  overflow-wrap: break-word;
}

img {
  height: auto;
  max-width: 100%;
}

@media screen and (max-width: 767px) {
  ul {
    padding-left: 20px;
  }
}
pre {
  padding: 1.25em 2em !important;
}

table {
  border-collapse: collapse;
}

th,
td {
  padding: 0.5em 1em;
  border: 1px solid #ccc;
}

/*
 * 円
 */
.animation-box--dark {
  background: -webkit-gradient(linear, left top, right bottom, from(#363636), color-stop(65%, #616163), to(#919191)) !important;
  background: linear-gradient(to bottom right, #363636, #616163 65%, #919191) !important;
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.animation-box--dark h3 {
  color: #fff !important;
}
.animation-box--dark a {
  color: #fff !important;
}

.circle-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin-top: 40px;
  position: relative;
}

.circle {
  background: #fff;
  border: 1px solid #363636;
  border-radius: 48px;
  color: #333;
  display: inline-block;
  font-size: 0.75em;
  font-weight: bold;
  height: 96px;
  line-height: 96px;
  text-align: center;
  width: 96px;
  cursor: pointer;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .circle {
    background: #fff;
    -webkit-transform: translate(15px, 5px);
            transform: translate(15px, 5px);
  }
}
/*
 * circle animation
 */
.animation-circle {
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-name: move-circle;
          animation-name: move-circle;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
.animation-circle:nth-child(1) {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}
.animation-circle:nth-child(2) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.animation-circle:nth-child(3) {
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}
.animation-circle:nth-child(4) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.animation-circle:nth-child(5) {
  -webkit-animation-delay: 1.25s;
          animation-delay: 1.25s;
}

@-webkit-keyframes move-circle {
  0% {
    -webkit-transform: translate(15px, 5px);
            transform: translate(15px, 5px);
  }
  50% {
    -webkit-transform: translate(-9px, -4px);
            transform: translate(-9px, -4px);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@keyframes move-circle {
  0% {
    -webkit-transform: translate(15px, 5px);
            transform: translate(15px, 5px);
  }
  50% {
    -webkit-transform: translate(-9px, -4px);
            transform: translate(-9px, -4px);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
/*
 * breadcrumbs
 * flex bread-crumbs layout
 */
.bread-crumbs-wrapper {
  margin: 2rem 1.5rem;
}

.bread-crumbs {
  text-align: right;
}

.clearfix::after {
  clear: both;
  content: "";
  display: block;
}

.file-search-wrapper {
  margin: 2em 20px;
}

.file-search input {
  border-left: none;
  border-right: none;
  border-top: none;
  font-size: 24px;
  line-height: 2;
}

/*
*
* @see
* https://www.bugbugnow.net/2020/02/font-family-definitive-edition.html
*/
body,
button,
input,
select,
textarea {
  font-family: "SF Pro JP", "SF Pro Text", "SF Pro Icons", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.75; /* 1.75 * 16px(想定フォントサイズ) = 28px */
}

html {
  font-size: 16px;
}

/*
 * footer
 */
footer p {
  text-align: center;
}

.form-label-text {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: 1.375em;
}
.form-label-text--required::after {
  color: #ff0000;
  content: "（必須）";
}

.form-select,
.form-text-area,
.form-text-field {
  background-color: #fff;
  border: 1px solid #363636;
  border-radius: 0.5rem;
  display: block;
  line-height: 1.375em;
  margin-top: 0.5rem;
  padding: 1rem 1.5rem;
  width: 100%;
}

.form-error-field {
  display: none;
  line-height: 1.375em;
  margin-top: 0.25em;
}

.submit-container {
  margin: 2.5rem 0 1rem;
  text-align: center;
}

#submit-btn {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: #363636;
  border: 0;
  border-radius: 1rem;
  color: #fff;
  line-height: 2em;
  padding: 0.75rem 0.75rem;
  width: 80%;
}

.animation-box--dark #submit-btn {
  background: #fff;
  color: #363636;
}

/*
 * header
 */
nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
nav a {
  color: #333;
}

@media screen and (min-width: 768px) {
  nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

header h1 {
  font-size: 32px;
}
header h1 a {
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  header h1 {
    font-size: 48px;
  }
}
.gnav {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0;
  padding: 0;
}
.gnav__item {
  -ms-flex-item-align: center;
      align-self: center;
  line-height: 32px;
  list-style-type: none;
  padding: 4px;
  text-align: center;
}
.gnav__item a {
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .gnav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}
/*
 * hero
 */
.hero {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 600px;
  max-width: 1400px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .hero {
    height: calc(100vh - 142px);
    padding: 0;
  }
}

.site-bg {
  margin: 0 auto;
  max-width: 1400px;
  padding: 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  overflow: hidden;
}

/* site wrapper */
.site-wrapper {
  margin: 0 auto 32px;
  max-width: 1400px;
  padding: 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 2;
}

/*
 * box
 * flex box layout
 */
.box-wrapper {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
}
@media screen and (min-width: 768px) {
  .box-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 32px 0 48px;
  }
}

.box,
.box--half {
  background: #fff;
  border: 1px solid #b6b6b6;
  border-radius: 10px;
  margin: 2rem 1rem;
  width: calc(100% - 32px);
}

.box--full {
  background: #fff;
  border: 1px solid #b6b6b6;
  border-radius: 10px;
  margin: 24px 16px;
  width: calc(100% - 32px);
}

.box--hero {
  border: none;
  padding: 0;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .box {
    -ms-flex-item-align: stretch;
        align-self: stretch;
    /* 7.5rem = 2.5rem * 3 */
    width: calc((100% - 7.5rem) / 2);
  }
  .box:nth-child(even) {
    margin: 1rem 1.25rem 1rem 2.5rem;
  }
  .box:nth-child(odd) {
    margin: 1rem 2.5rem 1rem 1.25rem;
  }
  .box--half {
    margin: 2rem auto !important;
    width: 780px;
  }
  .box--full {
    margin: 1rem 1.5rem;
    padding: 0;
  }
  .box--hero {
    margin-bottom: 24px;
  }
}
/*
 * panel
 */
.panel {
  margin: 0;
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
}

.panel-header {
  background: -webkit-gradient(linear, left top, right bottom, from(#363636), color-stop(65%, #4b4b4d), to(#575757));
  background: linear-gradient(to bottom right, #363636, #4b4b4d 65%, #575757);
  color: #fff;
  padding: 48px 64px;
}
@media screen and (max-width: 767px) {
  .panel-header {
    padding: 16px;
  }
}
.panel-header__caption {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.15;
  text-align: right;
}
.panel-header__caption .caption-number {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 35px;
  color: #333;
  display: inline-block;
  font-size: 0.75em;
  font-weight: bold;
  height: 70px;
  line-height: 70px;
  text-align: center;
  width: 70px;
}
.panel-header__title {
  font-size: 45.6px;
  font-weight: 700;
  line-height: 1.15;
  margin: 0 0 8px;
}
.panel-header__title--short {
  font-weight: bold;
  margin-bottom: 32px;
}
.panel-header__lead {
  font-size: 18px;
  font-weight: bold;
}
.panel-header__lead--slideup {
  -webkit-transform: translate(0, 50px);
          transform: translate(0, 50px);
}
.panel-header__lead--slideup-animation {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  -webkit-transition: all 2s ease-out;
  transition: all 2s ease-out;
}

.panel-body {
  font-size: 1em;
  padding: 64px 80px;
}
@media screen and (max-width: 767px) {
  .panel-body {
    padding: 16px;
  }
}
.panel-body--hero {
  padding: 32px 0;
}
@media screen and (max-width: 767px) {
  .panel-body--hero {
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .panel-header__title--animation {
    display: inline-block;
  }
  .caption-number {
    opacity: 0;
  }
  .caption-number--animation {
    -webkit-animation: all 0.5s;
            animation: all 0.5s;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-name: round;
            animation-name: round;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
@-webkit-keyframes round {
  0% {
    opacity: 0;
    -webkit-transform: translate(-100px, 0);
    transform: translate(-100px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes round {
  0% {
    opacity: 0;
    -webkit-transform: translate(-100px, 0);
    transform: translate(-100px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
/*
 * parts
 */
.parts {
  margin: 0;
  margin-top: 2rem;
  overflow: hidden;
  padding: 0;
  width: 100%;
}
.parts__caption {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.15;
  text-align: right;
}
.parts__heading {
  margin: 2rem 0;
}
.parts-heading__title {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.15;
  margin: 0.5rem 0;
}
.parts-heading__caption {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
}
.parts__lead {
  font-size: 1.1em;
  font-weight: bold;
}
.parts__body {
  font-size: 1em;
}

@media screen and (min-width: 768px) {
  .parts-cover {
    z-index: -1;
  }
}
.caption-small-number {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 30px;
  color: #333;
  display: inline-block;
  font-size: 0.75em;
  font-weight: bold;
  height: 60px;
  line-height: 60px;
  text-align: center;
  width: 60px;
}

@media screen and (max-width: 767px) {
  .caption-small-number {
    opacity: 0;
  }
  .caption-small-number--animation {
    -webkit-animation: all 0.5s;
            animation: all 0.5s;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-name: small-round;
            animation-name: small-round;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
@-webkit-keyframes small-round {
  0% {
    opacity: 0;
    -webkit-transform: translate(-100px, 0);
    transform: translate(-100px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes small-round {
  0% {
    opacity: 0;
    -webkit-transform: translate(-100px, 0);
    transform: translate(-100px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
/*
 * link
 */
a {
  color: #292929;
}

.external-link::after {
  content: url(../images/external.svg);
  display: inline-block;
  padding-left: 3px;
  width: 16px;
}

.animation-box--dark .external-link::after {
  content: url(../images/external-white.svg);
}

body {
  margin: 0;
}

#container {
  background-color: teal;
  height: 100vh;
  position: relative;
  z-index: 2;
}

.header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.mobile-menu {
  position: fixed;
  right: 0;
  top: 60px;
}

.mobile-menu__btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none !important;
}
.mobile-menu__btn > span {
  background: black;
  display: block;
  height: 2px;
  margin-bottom: 9px;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  width: 35px;
}
.mobile-menu__btn > span:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .mobile-menu__btn {
    display: none;
  }
}

.menu-open .gnav {
  display: block;
  height: 100vh;
  -webkit-transition: all 1s;
  transition: all 1s;
  width: 100vw;
}
.menu-open .gnav__item a {
  font-size: 30px;
  font-weight: bold;
  line-height: 42px;
  text-decoration: none;
}
.menu-open .gnav__item i {
  font-size: 30px;
  line-height: 42px;
}
.menu-open .mobile-menu__btn > span {
  background: black;
}
.menu-open .mobile-menu__btn > span:nth-child(1) {
  -webkit-transform: translateY(11px) rotate(135deg);
          transform: translateY(11px) rotate(135deg);
}
.menu-open .mobile-menu__btn > span:nth-child(2) {
  -webkit-transform: translateX(-18px) scaleX(0);
          transform: translateX(-18px) scaleX(0);
}
.menu-open .mobile-menu__btn > span:nth-child(3) {
  -webkit-transform: translateY(-11px) rotate(-135deg);
          transform: translateY(-11px) rotate(-135deg);
}

.rect {
  background-color: gray;
  height: 100px;
  margin: 20px;
  width: 100px;
}

.custom-shadow {
  -webkit-box-shadow: 0 3px 20px rgba(0, 0, 0, 0.6);
          box-shadow: 0 3px 20px rgba(0, 0, 0, 0.6);
}

.radius {
  border-radius: 20px;
}

.gradient {
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#000));
  background: linear-gradient(#fff, #000);
}

/*
 * Round animation
 */
.round-wrap--main {
  width: 360px;
  height: 360px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .round-wrap--main {
    height: 285px;
    width: 285px;
  }
}
.round-wrap--sub {
  width: 170px;
  height: 170px;
}
@media screen and (max-width: 767px) {
  .round-wrap--sub {
    display: none;
  }
}
.round-wrap--bg {
  width: 768px;
  height: 768px;
  position: relative;
  -webkit-transform: translateY(-80%);
          transform: translateY(-80%);
  opacity: 0.8;
}
@media screen and (max-width: 767px) {
  .round-wrap--bg {
    width: 380px;
    height: 380px;
  }
}

.round-content--main, .round-content--bg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 30% 70% 60% 40%/30% 40% 60% 70%;
  -webkit-animation: round-anime 5s linear infinite;
          animation: round-anime 5s linear infinite;
  background: -webkit-gradient(linear, left top, right bottom, from(#363636), color-stop(35%, #616163), to(#919191));
  background: linear-gradient(to bottom right, #363636, #616163 35%, #919191);
  background-color: #363636;
  position: absolute;
  top: 0;
  left: 0;
}
.round-content--sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 30% 70% 60% 40%/30% 40% 60% 70%;
  -webkit-animation: round-anime 5s linear infinite;
          animation: round-anime 5s linear infinite;
  background: -webkit-gradient(linear, left top, right bottom, from(#363636), color-stop(35%, #616163), to(#919191));
  background: linear-gradient(to bottom right, #363636, #616163 35%, #919191);
  background-color: #363636;
}

.round-bg--A {
  background: #fcb83f !important;
  opacity: 0.8;
}

.round-bg--B {
  background: #d98436 !important;
  opacity: 0.8;
}

.round-bg--C {
  background: #f07b47 !important;
  opacity: 0.9;
}

.round-bg--D {
  background: #d94a36 !important;
  opacity: 0.8;
}

.round-text--main {
  font-size: 2em;
  text-align: center;
  color: white;
  font-weight: bold;
}
.round-text--sub {
  font-size: 1em;
  text-align: center;
  color: white;
  font-weight: bold;
}

.main-text {
  z-index: 1;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 2em;
  text-align: center;
  color: white;
  font-weight: bold;
}

.round-anime--A {
  -webkit-animation: round-anime--A 5s linear infinite;
          animation: round-anime--A 5s linear infinite;
}

.round-anime--B {
  -webkit-animation: round-anime--B 5s linear infinite;
          animation: round-anime--B 5s linear infinite;
}

.round-anime--C {
  -webkit-animation: round-anime--C 5s linear infinite;
          animation: round-anime--C 5s linear infinite;
}

.round-anime--sub {
  -webkit-animation: round-anime--sub 5s linear infinite;
          animation: round-anime--sub 5s linear infinite;
}

@-webkit-keyframes round-anime--A {
  0% {
    border-radius: 30% 70% 60% 40%/30% 40% 60% 70%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    border-radius: 40% 40% 40% 40%/40% 40% 40% 40%;
  }
  50% {
    border-radius: 70% 30% 30% 70%/50% 70% 30% 50%;
  }
  75% {
    border-radius: 40% 40% 40% 40%/40% 40% 40% 40%;
  }
  100% {
    border-radius: 30% 70% 60% 40%/30% 40% 60% 70%;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes round-anime--A {
  0% {
    border-radius: 30% 70% 60% 40%/30% 40% 60% 70%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    border-radius: 40% 40% 40% 40%/40% 40% 40% 40%;
  }
  50% {
    border-radius: 70% 30% 30% 70%/50% 70% 30% 50%;
  }
  75% {
    border-radius: 40% 40% 40% 40%/40% 40% 40% 40%;
  }
  100% {
    border-radius: 30% 70% 60% 40%/30% 40% 60% 70%;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes round-anime--B {
  0% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    border-radius: 20% 80% 40% 60%/20% 80% 40% 60%;
  }
  25% {
    border-radius: 70% 30% 30% 70%/50% 70% 30% 50%;
  }
  50% {
    border-radius: 50% 50% 50% 50%/50% 50% 50% 50%;
  }
  75% {
    border-radius: 70% 30% 30% 70%/50% 70% 30% 50%;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border-radius: 20% 80% 40% 60%/20% 80% 40% 60%;
  }
}
@keyframes round-anime--B {
  0% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    border-radius: 20% 80% 40% 60%/20% 80% 40% 60%;
  }
  25% {
    border-radius: 70% 30% 30% 70%/50% 70% 30% 50%;
  }
  50% {
    border-radius: 50% 50% 50% 50%/50% 50% 50% 50%;
  }
  75% {
    border-radius: 70% 30% 30% 70%/50% 70% 30% 50%;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border-radius: 20% 80% 40% 60%/20% 80% 40% 60%;
  }
}
@-webkit-keyframes round-anime--C {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border-radius: 30% 70% 60% 40%/30% 40% 60% 70%;
  }
  25% {
    border-radius: 70% 30% 30% 70%/50% 70% 30% 50%;
  }
  50% {
    border-radius: 50% 50% 50% 50%/50% 50% 50% 50%;
  }
  75% {
    border-radius: 70% 30% 30% 70%/50% 70% 30% 50%;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    border-radius: 30% 70% 60% 40%/30% 40% 60% 70%;
  }
}
@keyframes round-anime--C {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border-radius: 30% 70% 60% 40%/30% 40% 60% 70%;
  }
  25% {
    border-radius: 70% 30% 30% 70%/50% 70% 30% 50%;
  }
  50% {
    border-radius: 50% 50% 50% 50%/50% 50% 50% 50%;
  }
  75% {
    border-radius: 70% 30% 30% 70%/50% 70% 30% 50%;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    border-radius: 30% 70% 60% 40%/30% 40% 60% 70%;
  }
}
@-webkit-keyframes round-anime--sub {
  0% {
    border-radius: 30% 70% 60% 40%/30% 40% 60% 70%;
  }
  25% {
    border-radius: 70% 30% 30% 70%/50% 70% 30% 50%;
  }
  50% {
    border-radius: 50% 50% 50% 50%/50% 50% 50% 50%;
  }
  75% {
    border-radius: 70% 30% 30% 70%/50% 70% 30% 50%;
  }
  100% {
    border-radius: 30% 70% 60% 40%/30% 40% 60% 70%;
  }
}
@keyframes round-anime--sub {
  0% {
    border-radius: 30% 70% 60% 40%/30% 40% 60% 70%;
  }
  25% {
    border-radius: 70% 30% 30% 70%/50% 70% 30% 50%;
  }
  50% {
    border-radius: 50% 50% 50% 50%/50% 50% 50% 50%;
  }
  75% {
    border-radius: 70% 30% 30% 70%/50% 70% 30% 50%;
  }
  100% {
    border-radius: 30% 70% 60% 40%/30% 40% 60% 70%;
  }
}
/*
 * flexbox tutorial
 */
.flex-item-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.flex-item-head {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 30%;
          flex: 0 1 30%;
}

.flex-item-body {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 70%;
          flex: 1 1 70%;
}

/*
 * eye catch（full width screen image) 
 */
.eye-catch-wrapper {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: transparent url("../images/eyecatch.jpg") center center/cover no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: 398px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
}

.catch-copy {
  background: #fff;
  font-size: 50px;
}

/*
 * アイコン付き見出し
 */
.h-icon {
  background: url("../images/icon.svg") no-repeat left center;
  /* background-size: 横 高 */
  background-size: 20px auto;
  padding-left: 20px;
}

/*
 * float box
 */
.float-wrapper {
  background: #eee;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  width: 100;
}

.no-float-box1 {
  background: blue;
  padding: 10px;
}

.no-float-box2 {
  background: green;
  padding: 10px;
}

.float-left-box {
  background: blue;
  float: left;
  padding: 10px;
}

.float-right-box {
  background: green;
  float: right;
  padding: 10px;
}

.document h1 {
  font-size: 2rem;
}
.document h2 {
  font-size: 1.75rem;
}
.document h3 {
  font-size: 1.5rem;
}
.document h4 {
  font-size: 1.25rem;
}
.document h5 {
  font-size: 1rem;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .document ul {
    padding-left: 10px;
  }
  .document ol {
    padding-left: 20px;
  }
}
.document blockquote {
  border-left: 3px solid #eee;
  padding-left: 20px;
}
.document blockquote blockquote {
  border-left: 3px solid #eee;
  margin-left: 0;
  padding-left: 10px;
}
@media screen and (max-width: 767px) {
  .document blockquote {
    margin: 1rem 0;
  }
}
.document pre {
  background: #eee;
  overflow: scroll;
  padding: 5px;
}
.document code {
  background: #eee;
  font-size: 0.8rem;
  padding: 5px;
}
